.para {
  color: white;
  font-size: 1rem;
  margin-bottom: 40px;
}

.title {
  text-align: center;
}

.list {
  color: white;
  font-size: 1rem;
  margin-bottom: 5px;
  margin-left: 40px;
}
li {
  color: white;
  margin-bottom: 5px;
}

.h5 {
  color: white;
}

.side {
  color: white;
  margin-left: 20px;
}

.third {
  margin-left: 40px;
}