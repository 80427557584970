.team_content {
  text-align: center;
  margin-bottom: 50p;
}

.our_team {
  padding: 0px;
}

.team_wrapper {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  margin-top: 50px;
}

.team_item {
  width: 25%;
  background: var(--team-card-bg);
  border-radius: 5px;
}

.team_img {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  background: white;
  overflow: hidden;
  padding-top: 10px;
}

.team_img img {
  width: 100%;
  height: 180px;
  object-fit: contain;
  transform: scale(1.3);
}

.team_details {
  padding: 20px 15px;
}

.team_details h4 {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 0.4rem;
}

.team_member-social {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  margin-top: 10px;
}

.team_member-social span {
  padding: 5px;
  background: var(--icon-bg);
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
