.header {
    width: 100%;
    height: 80px;
    line-height: 80px;
}

.nav_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu {
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    list-style: none;
}
.menu_link {
    font-weight: 400;
    font-size: .9rem;
    cursor: pointer;
}

.menu_link:hover {
    color: var(--link-active);
}
.menu_link:hover {
    color:rgb(51, 183, 163);
}

.logo h2 {
    color: var(--primary-color);
}

.light_mode span {
    color: rgba(255, 255, 255, 0.637);
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    font-size: 0.8rem;

}

.header_shrink {
    width: 100%;
    height: 80px;
    line-height: 80px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9999;
}