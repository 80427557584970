
.container4 {
    width: 100vw;
    background-color: white;
    
}

.block_wrapper2 {
    display: flex;
    justify-content: space-between;
    margin: auto;
    background-color: white;
    width: 1100px;
    height: 450px;

}

.block_content2,
.block_img2 {
    width: 50%;
    margin-top: 30px;
}

.block_img2 img {
    width: 40vw;
    margin-right: 20px;
}


.block_content2 h2 {
    font-size: 3rem;
    line-height: 65px;
}

.block_content2 p {
    margin-top: 40px;
    font-size: 1.1rem;
}

.highlight {
    color: var(--primary-color);
}




