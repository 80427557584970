
.container4 {
    width: 100vw;
    background-color: white;
    
}

.block_wrapper4 {
    display: flex;
    justify-content: space-between;
    margin: auto;
    background-color: white;
    width: 1100px;
    height: 450px;

}

.block_content4,
.block_img4 {
    width: 50%;
    margin-top: 30px;
}

.block_img4 img {
  width: 100%;
  height: 400px;
  margin-left: -50px;
  
}

.block_content4 h2 {
    font-size: 3rem;
    line-height: 65px;
}

.block_content4 p {
    margin-top: 40px;
    font-size: 1.1rem;
}

.highlight {
    color: var(--primary-color);
}




