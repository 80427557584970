.footer {
  padding: 60px 0px;
}
.footer_wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
}
.footer_logo {
  width: 40%;
}
.footer_quick-links {
  width: 20%;
}

.footer_logo h2 {
  color: var(--primary-color);
}

.small_text {
  font-size: 1rem;
  margin-top: 30px;
}

.quick_link-title {
  font-size: 1rem;
  color: var(--primary-color);
  font-weight: 500;
  margin-bottom: 20px;
}

.quick_link-title {
  margin-left: 30px;
}

.quick_link {
  list-style: none;
}

.quick_link-item {
  margin-bottom: 30px;
}

.quick_link-item a {
  font-weight: 300;
  cursor: pointer;
  font-size: 0.9rem;
  
}

.quick_link-item a:hover {
  color: var(--primary-color);
}

.copyright {
  color: rgba(255, 255, 255, 0.573);
  margin-top: 40px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
}
.dis2 {
  font-weight: 500;
  font-size: 20px;
}
