.wrapper {
    margin-top: 50px;
}

.title {
    text-align: center;
    margin-bottom: 50px;
    color: white;
    font-size: 3rem;
}
label {
    color: white;
}