.about_wrapper {
  display: flex;
  column-gap: 2rem;
}

.about_content,
.about_img {
  width: 50%;
}

.about_img img {
  width: 100%;
  height: 92%;
  object-fit: cover;
  border-radius: 0.5rem;
}
.about_content_desc {
  margin-top: 30px;
}
.choose_us-wrapper {
  margin-top: 20px;
}

.choose_us-item {
  padding: 20px 20px 20px 0;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-top: 15px;
}

.choose_us-icon {
  width: 50px;
  height: 60px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}

.choose_us-icon i {
  color: var(--primary-color);
  font-style: 2rem;
}

.choose_us-title {
  color: #fff;
  font-weight: 500;
}
