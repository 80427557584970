.services_top-content {
    text-align: center;
    margin-bottom: 50px;
}

.service_item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 2rem;
}
.desc {
    color: white;
    font-weight: 400;
    font-size: 1rem;
}

.services-item {
    background: var(--card-bg);
    width: 25%;
    padding: 30px 15px;
    text-align: center;
    border-radius: 0.5rem;
    cursor: pointer;
}

.service_icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background: var(--icon-bg);
    border-radius: 5px;
    margin-bottom: 30px;
    border: 1px solid var(--primary-color);

}

.service_icon i {
    font-size: 2rem;
    color: var(--primary-color);
}

.service_title {
    color: var(--primary-color);
    font-size: 1.3rem;
    margin-bottom: 30px;
}